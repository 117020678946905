import I18n from '../i18n';
import { debounce, inIframe, getTime, unescapeQuotes } from '../TCIUtils';

/**
 * A student/teacher Notebook Grid
 * @param config
 * @constructor
 */
export default function NotebookGrid(config) {
  this.url = config.url;
  this.id = config.id;
  this.rows = [];
  this.num_rows = 0;
  this.num_columns = 0;
  this.height = config.height || 375;
  this.is_teacher = config.is_teacher || false;
  this.read_only = config.read_only || false;
  this.dirty = false;
  this.set_recently_answered_question_id = config.set_recently_answered_question_id;
  this.show_update = config.show_update;
  this.originalMin = new Array;
  this.default_ratios = '50,50';
  this.default_cells = 2;
  this.preview = config.preview || false;
  this.editingStyles = config.editingStyles || {};
  this.hasInvokedInit = false;
  if (config.gridData) this._setGridData(config.gridData);
}

NotebookGrid.prototype = {
  /**
   * Initialize grid by GETting data and building DOM elements
   * @returns {NotebookGrid}
   */
  init: function() {
    var self = this;
    this.hasInvokedInit = true;

    this.grid_id = this.id.split('_')[1];

    if (this.grid_data) {
      this._buildWithReceivedData(false);
    }
    else {
      /** Get data to construct table */
      $.getJSON(this.url, function(data) {
        self._setGridData(data);
      }).fail((jqxhr, textStatus, error) => {
        console.log(`Request failed: ${textStatus}, ${error}`);
      }).done(function() {
        self._buildWithReceivedData();
      });
    }

    return this;
  },

  $getButtonDOM: function() {
    return $(`#save_${this.grid_id}`);
  },

  _setGridData: function(data) {
    this.grid_data = data.data === undefined ? data : data.data['object_data'];
    this.num_rows = this.grid_data.rows;
    this.num_columns = this.grid_data.columns;

    this.grid_data.chunk = JSON.parse(unescapeQuotes(this.grid_data.chunk));
  },

  _buildWithReceivedData: function(setListeners = true) {
    this._buildTable();
    this.button_node = $(`#save_${this.grid_id}`);

    if (this.button_node && setListeners) {
      this._setupAutoSaveListener();
      this._saveButtonEventListener();
      this._resizeGrid();
    }
  },

  /**
   * Build table (including DOM elements)
   * @returns {HTMLElement}
   * @private
   */

  _buildTable: function() {
    if (this.grid_data.chunk === undefined) {
      return this._buildDefaultTable();
    }

    const wrapper = document.getElementById(this.id);
    let row;
    let i;

    if (wrapper) {
      if (inIframe() || this.preview) {
        wrapper.style.display = 'block';
        wrapper.style.overflowX = 'auto';
      }

      if (this.show_update === 'true') $(`#question_answer_${this.grid_id}.save_message`).css('visibility', 'visible');

      this.tableElement = $(`<table id="${this.id}_table"></table>`)[0];

      wrapper.appendChild(this.tableElement);

      this.tableElement.style.minHeight = `${this.height}px`;
      this.tableElement.style.height = '0';

      this.control_row = document.createElement('tr');
      this.control_row.classList.add('grid-control-row');
      this.tableElement.appendChild(this.control_row);

      const normalized_ratios = this.normalizeRatios(this.grid_data.col_ratios.split(','));

      for (let j = 0; j < this.grid_data.col_ratios.split(',').length; j++) {
        const td = document.createElement('td');
        this.control_row.appendChild(td);
        td.style.width = `${normalized_ratios[j]}%`;
      }

      const firstEditableCell = this.grid_data.chunk.flat().find(cell => !cell.is_admin);
      if (firstEditableCell) firstEditableCell.isFirstEditableCell = true;

      for (i = 0; i < this.num_rows; i++) {
        row = new GridRow({
          node: this.tableElement.insertRow(i),
          data: this.grid_data.chunk[i],
          cols: this.num_columns,
          parent: this
        });

        row.init();

        this.rows.push(row);
      }

      /** Disable grid if it was previously answered or if read-only (teacher viewing grid) */
      if (this.read_only) {
        this._disableTable(true);
      }

      return wrapper;
    }
  },

  _buildDefaultTable: function() {
    const wrapper = document.getElementById(this.id);
    let row;
    let i;

    if (wrapper) {
      if (inIframe() || this.preview) {
        wrapper.style.display = 'block';
        wrapper.style.overflowX = 'auto';
      }

      if (this.show_update === 'true') $(`#question_answer_${this.grid_id}.save_message`).css('visibility', 'visible');

      wrapper.innerHTML = `<table id="${this.id}_table"></table>${wrapper.innerHTML}`;

      this.tableElement = document.getElementById(`${this.id}_table`);
      this.tableElement.style.height = `${this.height}px`;
      this.tableElement.style.minHeight = `${this.height}px`;

      this.control_row = document.createElement('tr');
      this.control_row.classList.add('grid-control-row');
      this.tableElement.appendChild(this.control_row);

      const normalized_ratios = this.normalizeRatios(['50', '50']);

      for (let j = 0; j < 2; j++) {
        const td = document.createElement('td');
        this.control_row.appendChild(td);
        td.style.width = `${normalized_ratios[j]}%`;
      }

      for (i = 0; i < 2; i++) {
        row = new GridRow({
          node: this.tableElement.insertRow(i),
          data: [{ colspan: 1, is_admin: false, rowspan: 1, val: '', style: { width: '50.0%', height: '50.0%'} }, { colspan: 1, is_admin: false, rowspan: 1, val: '', style: { width: '50.0%', height: '50.0%' } }],
          cols: 2,
          parent: this
        });

        row.init();

        this.rows.push(row);
      }

      /** Disable grid if it was previously answered or if read-only (teacher viewing grid) */
      if (this.read_only) {
        this._disableTable(true);
      }

      return wrapper;
    }
  },

  normalizeRatios: function(ratios) {
    const total = ratios.reduce((a, b) => {
      return parseFloat(a) + parseFloat(b);
    }, 0);

    return ratios.map((ratio) => {
      return ((parseFloat(ratio) / parseFloat(total)) * 100).toFixed(1);
    });
  },

  /**
   * Save button event listener
   */

  _saveButtonEventListener: function() {
    const self = this;
    (self.button_node).on('click', () => {
      let post_url;

      if (($(this).attr('data-enable-submit')) !== 'true') {
        return false;
      }
      /**
       * I don't think this is currently in use
       * It's intended to toggle based on whether you're editing
       * or not
       */

      if ($(this).hasClass('grid_saved')) {
        self._disableTable(false);
        // self.dirty = true;
        // self.button_node.attr('data-dirty','true')
        $(this).removeClass('grid_saved')
          .text('Save Your Answer')
          .attr('title', 'Save');
      }
      else if (self.is_teacher) {
        /** Teachers can't save grids */
        alert('Only students can save notebook answers.');
      }
      else {
        /** Proceed with save */
        $(this).attr('data-enable-submit', 'false');
        post_url = $(this).attr('post_url');

        debounce(() => { self._saveTable(post_url); }, 800);
      }
    });
  },

  _setupAutoSaveListener: function() {
    const self = this;
    const post_url = this.button_node.attr('post_url');

    $(this.tableElement).on('paste', debounce(() => {
      self._saveTable.call(self, post_url);
    }, 800));

    $(this.tableElement).on('keyup', debounce(function(e) {
      // don't do autosave if key is ctrl, ctrl + v is handled in paste event
      if (e.keyCode !== 91) self._saveTable.call(self, post_url);
    }, 800));

    $(this.tableElement).on('blur', function() {
      self._saveTable.call(self, post_url);
    });

    $(window).on('resize', this._resizeGrid.bind(this));
  },

  _resizeGrid: function() {
    if ($('body').data('layout') === 'responsive') {
      this.originalMin = this.originalMin || new Array;
      const zoomRatio = this.tableElement.offsetWidth / 920;
      this.tableElement.style.minHeight = this.tableElement.offsetHeight * zoomRatio + 'px';
      $(this.tableElement).find('div[contenteditable]').each(function(index, element) {
        this.originalMin[index] = this.originalMin[index] || element.style.minHeight;
        element.style.minHeight = parseInt(this.originalMin[index]) * zoomRatio + 'px';
      }.bind(this));
    }
  },

  answerData: function() {
    const table = [];

    for (let i = 0; i < this.num_rows; i++) {
      table.push(this.rows[i].get_data());
    }

    return {
      rows: this.num_rows,
      columns: this.num_columns,
      chunk: JSON.stringify(table)
    };
  },

  /**
   * Stringify table and save via Ajax
   * @param url
   * @param $element
   * @returns {Boolean}
   * @private
   */

  _saveTable: function(url) {
    var self = this,
      success;

    $.getJSON('/check_sign_on')
      .done(function(data) {
        if (data === true) {
          $("#question_answer_" + self.grid_id + ".save_message").stop().text('Saving...').css('visibility', 'visible').show();
          ajaxSave();
        }
        else {
          return $("#question_answer_" + self.grid_id + ".save_message").stop().text("Your account has signed in to another browser. Please continue your work in the other session or refresh the page to sign in again and continue.").addClass('failed').css('visibility', 'visible');
        }
      })
      .fail(function(data) {
        return $("#question_answer_" + self.grid_id + ".save_message").stop().text("Oh no! Your record did not save. Please try again. If you continue to experience this problem, please contact info@teachtci.com").addClass('failed').css('visibility', 'visible');
      });

    function ajaxSave() {
      $.post(url, {
        question_answer: {
          question_id: self.grid_id,
          answer_data: self.answerData()
        }
      })
        .done((res) => {
          if (self.set_recently_answered_question_id) self.set_recently_answered_question_id(self.grid_id);

          if (res.deleted) {
            self.$getButtonDOM().attr('data-answered', 'false');
          }
          else {
            self.$getButtonDOM().attr('data-answered', 'true');
          }

          const savedMessage = $(`#question_answer_${self.grid_id}.save_message`);
          const time = getTime();
          let savedString = `Saved at ${time.current_hour}:${time.fixed_minutes} ${time.am_pm}`;
          savedString += time.timezone ? ` ${time.timezone}` : '';
          success = true;
          self.dirty = false;
          self.button_node.attr('data-dirty', 'false');
          savedMessage.closest('.notebook-question').removeClass('highlight');
          return savedMessage.stop().text(savedString).removeClass('failed').addClass('successful');
        })
        .fail(() => {
          success = false;
          self.dirty = true;
          self.button_node.attr('data-dirty', 'true');
          return $(`#question_answer_${self.grid_id}.save_message`)
            .stop()
            .text('Save failed. Please try again. (Check your internet connection.)')
            .addClass('failed')
            .css('visibility', 'visible');
        });
    }

    return success;
  },


  /**
   * Disables/enables every grid cell
   * @param disable
   * @private
   * @returns {NotebookGrid}
   */

  _disableTable: function(disable) {
    for (let i = 0; i < this.rows.length; i++) {
      for (let j = 0; j < this.rows[i].cells.length; j++) {
        this.rows[i].cells[j].disable(disable);
      }
    }

    return this;
  }
};

function GridRow(config) {
  this.node = config.node;
  this.data = config.data;
  this.cols = config.cols;
  this.cells = [];
  this.parent = config.parent;
}

GridRow.prototype = {

  /**
   * Initializes grid Row and builds cell DOM elements
   * @returns {GridRow}
   */

  init: function() {
    var cell,
      width,
      height,
      i,
      skip_cell = 0,
      cell_num = 0,
      spans = 0;

    for (i = 0; i < this.cols; i++) {
      if (skip_cell > 0) {
        skip_cell--;
      }
      else if (this.cells.length < this.data.length) {
        cell = new GridCell({
          node: this.node.insertCell(cell_num),
          data: this.data[cell_num],
          parent: this
        });
        cell.init();

        /** If this is a multi-column cell, we
         * bump up skip_cell to accommodate
         */
        if (cell.span > 1) {
          skip_cell = cell.span - 1;
        }

        width = this.data[cell_num]['style']['width'] || '';
        height = this.data[cell_num]['style']['height'] || '';
        cell.setWidth(width);
        cell.setHeight(height);

        this.cells.push(cell);

        spans += cell.span;
        cell_num++;
      }
    }

    return this;
  },

  /**
   * Creates and array of cell data objects
   * @returns {Array}
   */

  get_data: function() {
    return this.cells.map((cell) => cell.get_data());
  }
};

/**
 * A notebook grid Cell
 * @param config
 * @constructor
 */
function GridCell(config) {
  this.node = config.node;
  this.data = config.data;
  this.isFirstEditableCell = config.isFirstEditableCell;
  this.is_admin = false;
  this.is_disabled = false;
  this.span = 1;
  this.rowspan = 1;
  this.parent = config.parent;
  this.editingStyles = this.parent.parent.editingStyles;
}

GridCell.prototype = {
  init: function() {
    const self = this;

    /** Admin cells are not editable */
    if (this.data) {
      this.is_admin = this.data.is_admin || this.is_admin;
    }

    this.val = this.formatVal(this.data.val);

    this.node.className += this.is_admin ? ' grid_admin_cell' : '';
    this.node.style.backgroundColor = '#ffffff';
    this.update(this.val);

    this.node.children[0].setAttribute(
      'contenteditable',
      !this.is_admin && !this.is_disabled
    );
    this.node.children[0].className += 'grid_td_content_div';
    if (this.data.isFirstEditableCell) {
      this.node.children[0].className += I18n.locale === 'es' ? ' with_es_placeholder' : ' with_placeholder';
    }

    /** If JSON has cell styling, apply it */
    if (this.data && this.data['style']) {
      /** Apply loaded colspan if it exists */
      this.span = (this.data['colspan'] !== undefined && this.data['colspan'] !== null) ? this.data['colspan'] : 1;
      if (this.span) {
        this.setSpan();
      }

      /** Apply loaded rowspan if it exists */
      this.rowspan = (this.data['rowspan'] !== undefined && this.data['rowspan'] !== null) ? this.data['rowspan'] : 1;
      if (this.rowspan) {
        this.setRowSpan();
      }

      /** Apply loaded position if it exists */
      this.position = this.data['position'];

      /** Apply loaded backgroundColor if it exists */
      this.backgroundColor = this.data['style']['backgroundColor'];
      if (this.backgroundColor) {
        this.setBackgroundColor();
      }

      if (!this.is_admin) {
        this.justification = 'left';
        this.font = this.editingStyles;

        if (this.font) {
          this.setFont();
        }

        if (this.justification) {
          this.setAlign();
        }
      }
      else {
        this.font = (this.data['style']['font'] !== undefined && this.data['style']['font'] != null) ? this.data['style']['font'] : { color: '#522e91', weight: 'bold' };
        this.justification = (this.data['style']['textAlign'] !== undefined && this.data['style']['textAlign'] != null) ? this.data['style']['textAlign'] : 'center';

        if (this.font) {
          this.setFont();
        }

        if (this.justification) {
          this.setAlign();
        }
      }
    }

    /* This is a bit sloppy since node has div, which can be deleted if user
     * selects all content and delete. But it's fine since val is text, not
     * html. (Blank cell content is <br> or "".)
     */
    $(this.node).on('click', function() {
      if (!(self.is_admin)) {
        $(this).find('> div').focus();
      }
    });

    $(this.node).on('keyup', function() {
      this.val = $(this.node).find('> div').html();
      this.parent.parent.dirty = true;
      if (this.parent.parent.button_node) {
        this.parent.parent.button_node.attr('data-dirty', 'true');
      }
    }.bind(this));

    $(this.node).find('div').on('focus', function() {
      $(self.node).css(
        Object.assign({ 'background-color': '#fffbe2' }, this.editingStyles)
      );
    });

    $(this.node).find('div').on('blur', function() {
      $(self.node).css({
        'background-color': '#ffffff'
      });
    });

    // sanitize any content pasted into cell, timeout of zero seconds delays execution
    // of statement enough for target element to be updated w pasted content
    $(this.node).on('paste', (e) => {
      const elem = e.target;
      setTimeout(() => {
        this.val = this.node.innerText;
        $(elem).text(this.node.innerText);
      }, 0);
    });
  },

  setWidth: function(width) {
    this.node.style.width = width;
    this.width = width;
  },

  setHeight: function(height) {
    this.node.style.height = height;
    this.height = height;
  },

  setSpan: function(span) {
    this.node.colSpan = span || this.span;
    return this;
  },

  setRowSpan: function(rowspan) {
    this.node.rowSpan = rowspan || this.rowspan;
  },

  setFont: function() {
    this.node.style.fontFamily = this.font.family || '';
    this.node.style.fontWeight = this.font.weight;
    this.node.style.color = this.font.color;

    return this;
  },

  setBackgroundColor: function() {
    this.node.style.backgroundColor = this.backgroundColor || 'inherit';
  },

  setAlign: function(justification) {
    this.node.style.textAlign = this.justification;

    return this;
  },

  update: function(content) {
    this.node.innerHTML = "<div>" + content + "</div>";
    this.val = content;
  },

  disable: function(disable) {
    if (this.is_admin) {
      return;
    }
    this.node.children[0].setAttribute('contenteditable', !disable);
  },

  get_data: function() {
    let font = {};
    if (this.font) {
      font = {
        color: this.font.color,
        family: this.font.family ? this.font.family.replace(new RegExp('"', 'g'), '\'') : '',
        weight: this.font.weight
      };
    }

    return {
      is_admin: this.is_admin,
      style: {
        width: this.width,
        height: this.height,
        font: font,
        textAlign: this.justification,
        backgroundColor: this.backgroundColor
      },
      colspan: this.span,
      rowspan: this.rowspan,
      position: this.position,
      val: this.val
    };
  },

  formatVal: function(val) {
    if (!this.parent.parent.preview) return this.data.val;

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = val;

    /* eslint-disable sort-keys */
    const sizeMap = {
      original: '3000px',
      small: '176px',
      medium: '356px',
      large: '757px',
      xlarge: '920px',
      background: '850px'
    };

    $(tempDiv).find('img').each((index, img) => {
      const platoAssetRegex = /\/system\/question_assets\/\d+\/(\S+)\//g;
      const srcMatch = [...img.src.matchAll(platoAssetRegex)][0];
      const imgSize = srcMatch && srcMatch[1];

      if (imgSize && Object.keys(sizeMap).includes(imgSize)) {
        // eslint-disable-next-line no-param-reassign
        img.src = img.src.replace(`/${imgSize}/`, '/original/');
        img.style.setProperty('maxWidth', sizeMap[imgSize]);
      }
    });

    return tempDiv.innerHTML;
  },
};
