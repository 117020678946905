import axios from 'axios';

export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this; const
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getTime() {
  const currentDate = new Date();
  let currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const amPm = (currentHour > 23 || currentHour < 12) ? 'AM' : 'PM';
  const fixedMinutes = (`0${currentMinutes}`).slice(-2);

  if (currentHour > 12) currentHour -= 12;

  return {
    am_pm: amPm,
    current_hour: currentHour,
    fixed_minutes: fixedMinutes,
    timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
  };
}

/**
 * Returns human readable time in the following format
 * H:MM AM/PM
 */

export function formattedTime() {
  const time = getTime();
  return `${time.current_hour}:${time.fixed_minutes} ${time.am_pm}`;
}

export function randomFromTo(from, to) {
  return Math.floor(Math.random() * (to - from + 1) + from);
}

export function lockElementToTop(className) {
  const $element = $(`.${className}`);
  const element_top = $element.offset().top;
  $(window).on('scroll', () => {
    refreshLockElement($element, element_top);
  });

  $element.on('click', () => {
    refreshLockElement($element, element_top);
  });
}

export function refreshLockElement($element, element_top) {
  const window_top = $(window).scrollTop();
  const $parentContainer = $('#parent-container');
  // don't lock element to top if it is larger than the window
  if (window.innerHeight <= $element.innerHeight()) return;
  if (window_top > element_top) {
    $element.addClass('lock_to_top');
  }
  else {
    $element.removeClass('lock_to_top');
  }
  if ($parentContainer.length) {
    let childHeight = '0';
    $parentContainer.find('*').each(function() {
      if ($(this).height() > childHeight) {
        childHeight = `${parseInt($(this).height(), 10) +
                      parseInt($(this).css('padding-top'), 10) +
                      parseInt($(this).css('margin-bottom'), 10)}px`;
      }
    });
    $parentContainer.height(childHeight);
  }
}

export function centerOnScreen(el) {
  let screen_width;
  let screen_height;
  let el_height;
  let el_width;

  screen_width = $(window).width();
  screen_height = $(window).height();
  el_height = $(el).height();
  el_width = $(el).width();

  $(el).css({
    position: 'absolute',
    'margin-top': 0,
    'margin-left': 0,
    top: screen_height / 2 - el_height / 2,
    left: screen_width / 2 - el_width / 2
  });
}

export function cacheBustString(str) {
  let new_str;

  new_str = (new Date()).getTime().toString() + randomFromTo(100, 200);

  return `${str}?${new_str}`;
}

/**
 * Select all checkboxes operate if your checkboxes are set up the following way:
 * <ul>
 *   <li>
 *     <label><input type="checkbox" class="select_all">Select All</label>
 *   </li>
 * </ul>
 * <ul>
 *   <li>
 *     <label><input type="checkbox"> Option</label>
 *   </li>
 *   etc.
 * </ul>
 */

export function setupSelectAllCheckboxes(inputs) {
  inputs.off('change').on('change', (e) => {
    const checkboxes = $(e.target).closest('li').next('ul').find('input');
    checkboxes.each(function() {
      if (e.target.checked) this.checked = true;
      else this.checked = false;
      this.setAttribute('data-dirty', true);
    });
  });
}

export function setSelectAllStates(selectAll, checked, checkboxes, indeterminate) {
  if (indeterminate.length > 0) {
    selectAll[0].checked = false;
    selectAll[0].indeterminate = true;
  }
  else if (checked.length === 0) { // none checked
    selectAll[0].checked = false;
    selectAll[0].indeterminate = false;
  }
  else if (checkboxes.length === checked.length) { // all checked
    selectAll[0].checked = true;
    selectAll[0].indeterminate = false;
  }
  else { // some checked
    selectAll[0].checked = false;
    selectAll[0].indeterminate = true;
  }
}

/**
 * This assumes you define a data-param = param you want to submit
 * and that you have an element in the form with that name.
 */

export function setupAutoSubmit($autoSubmit) {
  $autoSubmit.on('change', (e) => {
    const param = e.target.form[e.target.dataset.param];
    if (typeof param !== 'undefined') {
      param.value = e.target.value;
    }
    $("<input type='submit'>").css('display', 'none').appendTo(e.target.form).click();
  });
}

// assumes there is an element inside that is the toggle button
export function toggleClassOn($element) {
  const config = {
    className: $element.attr('data-class'),
    $target: $($element.attr('data-toggle-class-on')),
    $content: $($element.attr('data-toggle-text-on')),
    initialContent: $element.attr('data-initial'),
    finalContent: $element.attr('data-final'),
  };

  $element.on('click keypress', function(e) {
    if (e.type === 'keypress') {
      const key = e.which || e.keyCode || 0;
      if (key !== 13) return;
    }

    e.preventDefault();
    $element.parent().parent().toggleClass('active');
    this.$target.toggleClass(this.className);
    if (this.$content.length > 0 && this.initialContent && this.finalContent) {
      this.$content.text() === this.initialContent ? this.$content.text(this.finalContent) : this.$content.text(this.initialContent);
    }
  }.bind(config));
}

export function toggleClass(el, el_class) {
  $(el).on('click', () => {
    let classes;
    if (Array.isArray(el_class)) {
      classes = el_class;
    }
    else if (typeof el_class === 'string') {
      classes = el_class.split(' ');
    }

    for (let i = 0; i < classes.length; i++) {
      if ($(el).hasClass(classes[i])) {
        $(el).removeClass(classes[i]);
      }
      else {
        $(el).addClass(classes[i]);
      }
    }
  });
}

/**
 * Returns a query string built with the URL & passed in query
 * string.
 * @param url
 */
export function urlWithQueryString(url, queryString) {
  return `${url.split('?')[0]}?${queryString}`;
}

/**
 * The Fisher–Yates shuffle, also known as the Knuth shuffle, is an algorithm for generating
 * a random permutation of a finite set—in plain terms, for randomly shuffling the set.
 * @param array
 * @param seed
 * @returns array
 */
export function fisherYatesShuffle(array, seed = Math.random()) {
  let m = array.length;
  let t;
  let i;

  function random(s1) {
    const x = Math.sin(s1++) * 10000;
    return x - Math.floor(x);
  }

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);
    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

export function initVideoPlayers() {
  const players = $('video.aws_video');
  players.attr('preload', 'metadata');
  window.players = players.map((_, el) => (
    new MediaElementPlayer(el, {
      features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume'],
      poster: el.getAttribute('poster')
    })
  ));
}

/**
 * Returns true if we are rendered inside of an iframe,
 * false otherwise
 * @returns {boolean}
 */
export function inIframe() {
  return window.top != window.self;
}

/**
 * Returns a font-awesome spinner icon with
 * a default font size of 3em
 * @param fontSize
 * @returns {Element}
 */
export function spinner(fontSize) {
  const spinner = document.createElement('i');
  spinner.className = 'fa fa-spinner fa-spin fa-lg';
  spinner.style.fontSize = fontSize || '3em';
  return spinner;
}

/**
 * Reload if the back button was used to get to the current page
 */
export function reloadIfBackButtonUsed() {
  if (!!window.performance && window.performance.navigation.type === 2) {
    window.location.reload();
  }
}

/**
 * Returns the string with the first letter of each word capitalized
 * @param string
 */
export function capitalizeWords(string) {
  return string.split(' ').map(word => (word.charAt(0).toUpperCase()) + word.slice(1)).join(' ');
}

/**
 * Returns the string truncated at the designated length
 * @param string
 */
export function truncate(string, length) {
  return string.length > length ? `${string.substring(0, length)}…` : string;
}

/**
 * Good for polling HTTP endpoints
 * Polls until the until callback returns true
 * TODO: consolidate with `poll`
 *
 * @param endpointUrl
 * @param until
 * @param timeout
 * @param interval
 * @returns {Promise<any>}
 */

export function pollEndpoint({
  endpointUrl, until, timeout = 30000, interval = 2000
}) {
  const endTime = Number(new Date()) + timeout;

  const checkCondition = function(resolve, reject) {
    return axios.get(endpointUrl).then(function(response) {
      if (until(response)) {
        return resolve(response);
      }
      if (Number(new Date()) < endTime) {
        return setTimeout(checkCondition, interval, resolve, reject);
      }
      return reject(new Error(`timed out for ${endpointUrl}: ${arguments}`));
    });
  };

  return new Promise(checkCondition);
}

/**
 * Poll until given fn returns true, with timeout built in.
 *
 * @param fn
 * @param timeout
 * @param interval
 * @returns {Promise<any>}
 */
export function poll(fn, timeout, interval) {
  const endTime = Number(new Date()) + (timeout || 3000);
  const pollInterval = (interval || 500);

  const checkCondition = (resolve, reject) => {
    const result = fn();
    if (result) resolve(result);
    else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, pollInterval, resolve, reject);
    }
    else reject(new Error(`Timed out for ${fn}`));
  };

  return new Promise(checkCondition);
}

/**
 * Returns a hex-code string that derives from an rgb/rgba value
 * @param string
 */

export function rgb2hex(rgb_code) {
  const rgb = rgb_code.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgb && rgb.length === 4) ? `#${
    (`0${parseInt(rgb[1], 10).toString(16)}`).slice(-2)
  }${(`0${parseInt(rgb[2], 10).toString(16)}`).slice(-2)
  }${(`0${parseInt(rgb[3], 10).toString(16)}`).slice(-2)}` : '';
}

/**
 * Returns an object grouped by the key argument
 * @param arr
 * @param key
 */

export function groupBy(arr, key) {
  return arr.reduce((acc, currentObj) => {
    (acc[currentObj[key]] = acc[currentObj[key]] || []).push(currentObj);

    return acc;
  }, {});
}

/**
 * Same as groupBy, but returns an array grouped by the key argument (uses 'vals' key to collect array of
 * grouped objects)
 *
 * ex output: [
 *   { myKey: 'test', vals: [
 *       { myKey: 'test', value: 3 },
 *       { myKey: 'test', value: 5 }
 *     ]
 *   },
 *   { myKey: 'another', vals: [
 *       { myKey: 'another', value: 4 }
 *     ]
 *   }
 * ]
 *
 * @param arr
 * @param groupKey
 */

export function groupByAsArray(arr, groupKey) {
  return arr.reduce((acc, currentObj) => {
    const matched_object = acc.find(obj_element => obj_element && obj_element[groupKey] === currentObj[groupKey]);

    if (matched_object) {
      matched_object.vals.push(currentObj);
    }
    else {
      const pushObj = {};

      // I split this out (rather than setting the object literal in one line,
      // so we can reuse the groupKey as a variable,
      pushObj[groupKey] = currentObj[groupKey];
      pushObj.vals = [currentObj];
      acc.push(pushObj);
    }

    return acc;
  }, []);
}

export function unescapeQuotes(val) {
  return val.replace(/=\\{2}"/g, '=\\"').replace(/\\{2}">/g, '\\">');
}

// Returns a new canvas element with a thumbnail
// from the passed in video
export function thumbnailFromCanvas(video, maxWidth, maxHeight) {
  const canvas = document.createElement('canvas');
  canvas.id = 'thumbnail_canvas';
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  if (maxWidth) canvas.style.maxWidth = maxWidth;
  if (maxHeight) canvas.style.maxHeight = maxHeight;
  return canvas;
}

// Create a file object from canvas element
export function createDataURI(canvas) {
  if (canvas) {
    const dataURI = canvas.toDataURL('image/jpeg');
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    return blob;
  }
  return null;
}

/**
 * Generate 11 digit random string
 * @returns {string}
 */
export function randomString() {
  return Math.random().toString(36).substring(2, 15);
}

export function appendRandomQueryString(url) {
  // if url already contains query, append a new one
  if (url.includes('?')) {
    return url.concat(`&${randomString()}`);
  }
  return url.concat(`?${randomString()}`);
}

/**
 * Performs a shallow check of values between arrays, order-dependent.
 * @param a Array
 * @param b Array
 * @returns {boolean}
 */
export function checkArrayEquality(a, b) {
  if (a && b) {
    if (a.length !== b.length) return false;

    for (let i = a.length; i--;) {
      if (a[i].value !== b[i].value) return false;
    }

    return true;
  }

  return a === b;
}

/**
 * Performs a check of values between sets, order-independent.
 * @param a Set
 * @param b Set
 * @returns {boolean}
 */
export function checkSetEquality(a, b) {
  if (a && b) {
    if (a.size !== b.size) return false;

    return [...a].every(value => b.has(value));
  }

  return a === b;
}

/**
 * Creates a new array with all sub-array elements concatenated into it
 * recursively. To be used while `.flat()` is still in experimental stages.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#Browser_compatibility
 * @param array
 * @returns {*}
 */
export function flattenDeepArray(array) {
  return array
    .reduce(
      (acc, val) => (
        Array.isArray(val) ? acc.concat(flattenDeepArray(val)) : acc.concat(val)
      ),
      []
    );
}

/**
 * Deep flattens a given object by prefixing the key to each child's key.
 * E.g. { a: 1, b: { c: 2 } } would be deep flattened to { a: 1, b_c: 2 }.
 * @param object The object to be flattened
 * @param prefix String to prefix the key with when adding to the accumulator
 * @returns {{}} The flattened object
 */
export function flattenDeep(object, prefix = '', flattenArray = true) {
  const keys = Object.keys(object);
  return keys.reduce((acc, key) => {
    const value = object[key];
    const newKey = prefix.length > 0 ? `${prefix}_${key}` : key;

    if (value && typeof value === 'object' && (flattenArray || !Array.isArray(value))) {
      return Object.assign(acc, flattenDeep(value, newKey));
    }

    return Object.assign(acc, { [newKey]: value });
  }, {});
}

/**
 * Returns a string of the date from a Timestamp.
 * E.g. returns time in format "5/24/2019".
 * @param timestamp The Timestamp as a Timestamp string or Unix Timestamp (in milliseconds)
 * @param locale An optional locale
 * @returns {string} The date and time in the format "5/24/2019"
 */
export function getDate(timestamp, locale = undefined) {
  return formatAsDate(new Date(convertTimestamp(timestamp)), locale);
}

/**
 * Returns a string of the date and time from a Timestamp.
 * E.g. returns time in format "5/24/2019, 12:06 PM".
 * @param timestamp The Timestamp as a Timestamp string or Unix Timestamp (in milliseconds)
 * @param locale An optional locale
 * @returns {string} The date and time in the format "5/24/2019, 12:06 PM"
 */
export function getDateTime(timestamp, locale = undefined) {
  return formatAsDateTime(new Date(convertTimestamp(timestamp)), locale);
}

function convertTimestamp(timestamp) {
  // Check if timestamp is a Unix Timestamp (milliseconds):
  if (typeof timestamp === 'number') {
    return timestamp * 1000;
  }

  return timestamp;
}

function formatAsDate(dateObject, locale = undefined) {
  return dateObject.toLocaleDateString(locale, { month: '2-digit', day: '2-digit', year: 'numeric' });
}

function formatAsTime(dateObject, locale = undefined) {
  return dateObject.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
}

function formatAsDateTime(dateObject, locale = undefined) {
  const date = formatAsDate(dateObject, locale);
  const time = formatAsTime(dateObject, locale);
  return `${date}, ${time}`;
}

export function arrayToAjaxParams(arr, param) {
  return arr.map(el => `${param}[]=${el}`).join('&');
}

export function reloadIframe(iframe) {
  iframe.src += ''; // append empty string to the iframe src to reload it
}

export const makeHtmlSafe = (data) => {
  const div = document.createElement('div');
  div.innerText = data;
  return div.innerHTML;
};

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const toSnake = key => key.replace(/([A-Z])/g, '_$1').toLowerCase();
const toCamel = key => key.replace(/(_\w)/g, k => k[1].toUpperCase());

/**
 * Converts the keys of the passed object to snake case
 * ex:
 * > toSnakeCase({ testString: 'abc', test_obj: { testNum: 3 } })
 * { test_string: 'abc', test_obj: { test_num: 3 } }
 * @param obj
 * @returns {object}
 */
export const toSnakeCase = obj => Object.keys(obj || {}).reduce((result, key) => ({
  ...result,
  [toSnake(key)]: (typeof (obj[key]) === 'object' && obj[key] !== null && !Array.isArray(obj[key])) ? toSnakeCase(obj[key]) : obj[key]
}), {});

/**
 * Converts the keys of the passed object to camel case. ignores arrays of objects.
 * ex:
 * > toCamelCase({ test_string: 'abc', testObj: { test_num: 3 } })
 * { testString: 'abc', testObj: { testNum: 3 } }
 * @param obj
 * @returns {object}
 */
export const toCamelCase = obj => Object.keys(obj || {}).reduce((result, key) => ({
  ...result,
  [toCamel(key)]: (typeof (obj[key]) === 'object' && obj[key] !== null && !Array.isArray(obj[key])) ? toCamelCase(obj[key]) : obj[key]
}), {});

/**
 * Converts the keys of the passed object to camel case
 * ex:
 * > toCamelCase({ test_string: 'abc', test_obj: { test_num: 3 }, test_arr: [{test_letter: a}, {test_letter: b}, {test_letter: c}] })
 * { testString: 'abc', testObj: { testNum: 3 }, testArr: [{testLetter: a}, {testLetter: b}, {testLetter: c}] }
 * @param obj
 * @returns {object}
 */
export const toCamelCaseDeep = obj => Object.keys(obj || {}).reduce((result, key) => {
  if (!Array.isArray(obj[key])) {
    return ({
      ...result,
      [toCamel(key)]: (typeof (obj[key]) === 'object' && obj[key] !== null) ? toCamelCase(obj[key]) : obj[key]
    });
  }
  return ({
    ...result,
    [toCamel(key)]: obj[key].map(toCamelCase),
  });
}, {});
